import React from 'react';
import { Routes, Route } from "react-router-dom";

import Login from '../pages/Login';
import MainLayout from '../pages/MainLayout';
import Dashboard from '../pages/Dashboard';
import Disciplina from '../pages/Disciplina';
import DocMatric from '../pages/DocumentosMatricula';
import Periodo from '../pages/Periodo';
import Materia from '../pages/Materia';
import Curso from '../pages/Curso';
import MatrizCurricular from '../pages/MatrizCurricular';
import CodigoOcorrencia from '../pages/CodigoOcorrencia';
import Origem from '../pages/Origem';
import FormaIngresso from '../pages/FormaIngresso';
import Setor from '../pages/Setor';
import Turmas from '../pages/Turmas';
import OcorrenciaEstorno from '../pages/OcorrenciaEstorno';
import Convenio from '../pages/Convenio';
import FeriadoFinanceiro from '../pages/FeriadoFinanceiro';
import FormaRecebimento from '../pages/FormaRecebimento';
import Cartao from '../pages/Cartao';
import ParcelaExtra from '../pages/ParcelaExtra';
import PlanoConta from '../pages/PlanoConta';
import DisciplinaAprovaBaseMedia from '../pages/DisciplinaAprovaBaseMedia';
import NivelSequencia from '../pages/NivelSequencia';
import FonteFinanceira from '../pages/FonteFinanceira';
import PlanoPagamento from '../pages/PlanoPagamento';
import CentroCusto from '../pages/CentroCusto';
import PlanoPagamentoTurma from '../pages/PlanoPagamentoTurma';
import Endereco from '../pages/Enderecos';
import Funcionario from '../pages/Funcionario';
import OfertaDisciplina from '../pages/OfertaDisciplina';
import Alunos from '../pages/Alunos';
import ItinerarioFormativo from '../pages/ItinerarioFormativo';
import AlunoSituacao from '../pages/AlunoSituacao';
import Estagios from '../pages/Estagios';
import Visitantes from '../pages/Visitantes';
import Formados from '../pages/Formados';
import AlunosEstagios from '../pages/AlunosEstagios';
import NotasAlunos from '../pages/NotasAlunos';
import FrequenciaAlunos from '../pages/FrequenciaAlunos';
import FrequenciaGlobal from '../pages/FrequenciaGlobal';
import TurmaAuxiliar from '../pages/TurmaAuxiliar';
import FrequenciaDiaria from '../pages/FrequenciaDiaria';
import StatusPorDisciplina from '../pages/StatusPorDisciplina';
import OrganizaNumeroOrdem from '../pages/OrganizaNumeroOrdem';
import DisciplinasComplementares from '../pages/DisciplinasComplementares';
import ItinerarioTurma from '../pages/ItinerarioTurma';
import GerenciadorUsuarios from '../pages/GerenciadorUsuarios';
import Empresas from '../pages/Empresas';
import ConfiguracoesSecretaria from '../pages/ConfiguracoesSecretaria';
import TrocaTurmaAluno from '../pages/TrocaTurmaAluno';
import Bancos from '../pages/Bancos';
import ConfiguracoesFinanceiro from '../pages/ConfiguracoesFinanceiro';
import Perfil from '../pages/Perfil';
import NotasTurma from '../pages/NotasTurma';
import Boletos from '../pages/Boletos';
import ColaboradorFormImp from '../pages/Impressao/ColaboradorFormImp';
import InstrumentoAvaliacao from '../pages/InstrumentoAvaliacao';
import AbastecimentoCaixa from '../pages/AbastecimentoCaixa';
import ContasAPagar from '../pages/ContasAPagar';
import ConselhoClasseEdInfantil from '../pages/ConselhoClasseEdInfantil';
import Sangria from '../pages/Sangria';
import ZeraMensalidades from '../pages/ZeraMensalidades';
import HistoricoEscolar from '../pages/HistoricoEscolar';
import AlunosTurmasAuxiliares from '../pages/AlunosTurmasAuxiliares';
import OutrosRecebimentos from '../pages/OutrosRecebimentos';
import HistoricoObservacao from '../pages/HistoricoObservacao';
import AlunosFormValoresComplementares from '../pages/AlunosFormValoresComplementares';
import CartaoInstitucional from '../pages/CartaoInstitucional';
import LiquidaFaturaCartao from '../pages/LiquidaFaturaCartao';
import EncerramentoCaixa from '../pages/EncerramentoCaixa';

const MainRoutes: React.FC = () => {

  return (
    <Routes>
      <Route path='/:Codigo?' element={<Login />} />
      <Route path='/Periodos' element={<Periodo />} />
      <Route element={<MainLayout />}>
        <Route path='/Tabelas/MateriaDisciplina' element={<Materia />} />
        <Route path='/Tabelas/Disciplina' element={<Disciplina />} />
        <Route path='/Tabelas/Curso' element={<Curso />} />
        <Route path='/Tabelas/DocumentosMatricula' element={<DocMatric />} />
        <Route path='/Tabelas/MatrizesCurriculares' element={<MatrizCurricular />} />
        <Route path='/Tabelas/NivelSequencia' element={<NivelSequencia />} />
        <Route path='/Tabelas/Turmas' element={<Turmas />} />
        <Route path='/home' element={<Dashboard />} />
        <Route path='/Tabelas/CodigoOcorrencia' element={<CodigoOcorrencia />} />
        <Route path='/Tabelas/origem' element={<Origem />} />
        <Route path='/Tabelas/FormaIngresso' element={<FormaIngresso />} />
        <Route path='/Administrativo/Setor' element={<Setor />} />
        <Route path='/Tabelas/DisciplinaAprovaBaseMedia' element={<DisciplinaAprovaBaseMedia />} />
        <Route path='/Tabelas/OcorrenciaEstorno' element={<OcorrenciaEstorno />} />
        <Route path='/Tabelas/Convenio' element={<Convenio />} />
        <Route path='/Tabelas/FeriadoFinanceiro' element={<FeriadoFinanceiro />} />
        <Route path='/Tabelas/FormaRecebimento' element={<FormaRecebimento />} />
        <Route path='/Tabelas/Cartao' element={<Cartao />} />
        <Route path='/Tabelas/ParcelaExtra' element={<ParcelaExtra />} />
        <Route path='/Tabelas/PlanoConta' element={<PlanoConta />} />
        <Route path='/Tabelas/FonteFinanceira' element={<FonteFinanceira />} />
        <Route path='/Tabelas/PlanoPagamento' element={<PlanoPagamento />} />
        <Route path='/Tabelas/CentroCusto' element={<CentroCusto />} />
        <Route path='/Tabelas/PlanoPagamentoTurma' element={<PlanoPagamentoTurma />} />
        <Route path='/Administrativo/Funcionarios' element={<Funcionario />} />
        <Route path='/Tabelas/OfertaDisciplina' element={<OfertaDisciplina />} />
        <Route path='/Tabelas/ItinerarioFormativo' element={<ItinerarioFormativo />} />
        <Route path='/OutrasAtv/AlunosSituacao' element={<AlunoSituacao />} />
        <Route path='/Cadastro/Enderecos' element={<Endereco />} />
        <Route path='/Cadastro/Alunos' element={<Alunos />} />
        <Route path='/Cadastro/Estagios' element={<Estagios />} />
        <Route path='/Cadastro/AlunosTurmasAuxiliares' element={<AlunosTurmasAuxiliares />} />
        <Route path='/Cadastro/Visitantes' element={<Visitantes />} />
        <Route path='/Cadastro/Formados' element={<Formados />} />
        <Route path='/Cadastro/AlunosEstagios' element={<AlunosEstagios />} />
        <Route path='/Notas/Alunos' element={<NotasAlunos />} />
        <Route path='/Notas/Turmas' element={<NotasTurma />} />
        <Route path='/Frequencias/Alunos' element={<FrequenciaAlunos />} />
        <Route path='/Frequencias/Global' element={<FrequenciaGlobal />} />
        <Route path='/Tabelas/TurmaAuxiliar' element={<TurmaAuxiliar />} />
        <Route path='/Frequencias/Diario' element={<FrequenciaDiaria />} />
        <Route path='/OutrasAtv/StatusDisciplina' element={<StatusPorDisciplina />} />
        <Route path='/OutrasAtv/OrganizaNumeroOrdem' element={<OrganizaNumeroOrdem />} />
        <Route path='/OutrasAtv/DisciplinasComp' element={<DisciplinasComplementares />} />
        <Route path='/OutrasAtv/ItinerarioTurma' element={<ItinerarioTurma />} />
        <Route path='/Gerenciador/Usuarios' element={<GerenciadorUsuarios />} />
        <Route path='/Gerenciador/Perfil' element={<Perfil />} />
        <Route path='/Gerenciador/Empresas' element={<Empresas />} />
        <Route path='/ConfiguracoesSecretaria' element={<ConfiguracoesSecretaria />} />
        <Route path='/OutrasAtv/TrocaTurmaAluno' element={<TrocaTurmaAluno />} />
        <Route path='/ConfiguracoesFinanceiro' element={<ConfiguracoesFinanceiro />} />
        <Route path='/Bancario/Bancos' element={<Bancos />} />
        <Route path='/Financeiro/Boletos' element={<Boletos />} />
        <Route path='/Notas/Instrumentos' element={<InstrumentoAvaliacao />} />
        <Route path='/Financeiro/AbastecimentoCaixa' element={<AbastecimentoCaixa />} />
        <Route path='/Financeiro/ContasApagar' element={<ContasAPagar />} />
        <Route path='/Notas/ConselhoClasse' element={<ConselhoClasseEdInfantil />} />
        <Route path='/Financeiro/Sangria' element={<Sangria />} />
        <Route path='/Financeiro/ZeraMensalidades' element={<ZeraMensalidades />} />
        <Route path='/Financeiro/OutrosRecebimentos' element={<OutrosRecebimentos />} />
        <Route path='/Historico/HistoricoEscolar' element={<HistoricoEscolar />} />
        <Route path='/Historico/HistoricoObservacao' element={<HistoricoObservacao />} />
        <Route path='/Financeiro/ValoresComplementares' element={<AlunosFormValoresComplementares />} />
        <Route path='/Tabelas/CartaoInstitucional' element={<CartaoInstitucional />} />
        <Route path='/Financeiro/LiquidaFatura' element={<LiquidaFaturaCartao />} />
        <Route path='/Financeiro/EncerramentoCaixa' element={<EncerramentoCaixa />} />
      </Route>
    </Routes>
  )
}

export default MainRoutes;