import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IOrigem from '../interfaces/IOrigem';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import OrigemForm from './OrigemForm';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import IFonteFinanceira from '../interfaces/IFonteFinanceira';
import FonteFinanceiraForm from './FonteFinanceiraForm';
import { IoMdCheckboxOutline } from "react-icons/io";


const FonteFinanceira = () => {
  const toast = useToast();
  const tarefa = 'FonteFinanceiraForm';
  const { periodoSelecionado } = useAuth();

  let emptyFonteFinanceira: IFonteFinanceira = {
    FonteId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Codigo: '',
    Desativado: false,
    Tipo: 0,
    BancoCodigo: "",
    BancoAgencia: "",
    BancoConta: "",
    BancoSaldo: 0,
    BancoDataSaldo: new Date(''),
  };

  const [fontes, setFontes] = useState<any>(null);
  const [fonteDialog, setFonteDialog] = useState<boolean>(false);
  const [deleteFonte, setDeleteFonte] = useState<boolean>(false);
  const [fonte, setFonte] = useState<IFonteFinanceira>(emptyFonteFinanceira);
  const [selectedFonte, setSelectedFonte] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyFonteFinanceira });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/fontes/list', { params: { Codigo: getValues()?.Codigo, EmpresaId: periodoSelecionado?.EmpresaId } });

    if (result.data)
      setFontes(result.data);
    else
      setFontes([]);
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setFonte(emptyFonteFinanceira);
    setSubmitted(false);
    setFonteDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setFonteDialog(false);
  };
  const editar = (fonte: any) => {
    fonte.BancoDataSaldo = fonte.BancoDataSaldo ? new Date(fonte.BancoDataSaldo) : null;
    setFonte(fonte);
    setFonteDialog(true);
  };

  const confirmDelete = (item: any) => {
    setFonte(item);
    setDeleteFonte(true);
    setFonteDialog(true);
  };

  const deletar = async () => {
    try {

      if (fonte.Codigo === 'MEU CAIXA' || fonte.Codigo === 'CAIXA') {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Não é possível excluir a fonte de CAIXA / MEUCAIXA.' });
        return;
      }

      const resp = await api.delete('/fontes', { params: { FonteId: fonte.FonteId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteFonte(false);
      setFonteDialog(false);
      setFonte(emptyFonteFinanceira);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Fontes Financeiras</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Codigo"
                  label='Fonte'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={fontes}
              onSelectionChange={(e) => setSelectedFonte(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Codigo" header="Fonte" style={{ width: 90 }} sortable></Column>

              <Column hidden={!util.isDesktop()} field="Tipo" header="Tipo"
                body={(rowData: IFonteFinanceira) => (
                  rowData.Tipo == 1 ? 'Caixa Geral' : rowData.Tipo == 2 ? 'Banco' : 'Outros'
                )}

              />

              <Column field="BancoCodigo" header="Banco" style={{ width: 90 }} sortable></Column>
              <Column field="BancoAgencia" header="Agência" style={{ width: 90 }} sortable></Column>
              <Column field="BancoConta" header="Conta" style={{ width: 90 }} sortable></Column>

              <Column field="Desativado" header='Desabilitado' align='center' sortable body={(rowData) => {
                return rowData.Desativado ? <IoMdCheckboxOutline size={20} color="red" /> : '';
              }}></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    {(rowData.Codigo !== 'MEU CAIXA' && rowData.Codigo !== 'CAIXA') && (
                      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                    )}
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={fonteDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${fonte.FonteId === undefined ? 'Cadastrando ' : (deleteFonte ? 'Excluíndo' : 'Alterando')} Fonte Financeira`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <FonteFinanceiraForm
              setFonteDialog={setFonteDialog}
              fonte={fonte}
              reload={load}
              deleteFonte={deleteFonte}
              setDeleteFonte={setDeleteFonte}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default FonteFinanceira;
