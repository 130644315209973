import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, CARTAO_BANDEIRA } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCDropdown } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { useAuth } from '../providers/auth';
import ICartao from '../interfaces/ICartao';
import CartaoInstitucionalForm from './CartaoInstitucionalForm';


const CartaoInstitucional = () => {
    const toast = useToast();
    const tarefa = 'CartaoInstitucional';

    let emptyCartao: ICartao = {
        CartoesId: undefined,
        Institucional: false,
        NumeroCartao: 0,
        FonteSugeridaId: 0,
        CentroCustoId: 0,
        DiaPadraoVencimento: undefined,
        NomeCartao: '',
    };

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [selectedCartao, setSelectedCartao] = useState<any>(null);
    const [cartao, setCartao] = useState<ICartao>(emptyCartao);
    const [cartoes, setCartoes] = useState<any>();
    const [filter, setFilter] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const dt = useRef<any>(null);
    const menu = useRef<any>(null);

    const methods = useForm({ defaultValues: emptyCartao });
    const { handleSubmit, control, formState: { errors }, getValues, watch, setValue } = methods;
    const { periodoSelecionado } = useAuth();

    const watchCartao = watch('CartoesId')

    const load = async () => {
        setLoading(true)

        try {
            const result = await api.get('/cartoes/listCartaoInstitucional', { params: { Institucional: 1 } });
            if (result.data) {
                setCartoes(result.data);
            } else {
                setCartoes([])
            }
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }

        setLoading(false)
    };

    useEffect(() => {
        load();
    }, []);

    const openNew = () => {
        setCartao(emptyCartao);
        setShowDialog(true);
    };

    const hideDialog = () => {
        setShowDialog(false);
    };

    const editar = (cartao: any) => {
        setCartao(cartao);
        setShowDialog(true);
    };

    const confirmDelete = (item: any) => {
        setCartao(item);
        setShowDelete(true);
        setShowDialog(true);
    };

    const deleteCartaoInstitucional = async () => {
        try {
            await api.delete('/cartoes', { params: { CartoesId: cartao.CartoesId } });
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
            load();
            setShowDelete(false);
            setShowDialog(false);
            setCartao(emptyCartao);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }

    };

    const onSubmit = async (data: any) => {
        load();
    }

    const opcoes = [
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Exp. para Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Ajuda',
            icon: 'pi pi-question-circle',
            command: () => {
                dt.current.exportCSV();
            }
        },
    ];


    const openMenu = (event: any) => {
        menu?.current?.toggle(event)
    };

    const cancelar = (e: any) => {
        setShowDelete(false);
        setShowDialog(false);
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Cartão Institucional</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <SCDropdown
                                    name="CartoesId"
                                    options={cartoes}
                                    optionLabel="FormaDescricao"
                                    optionValue='CartoesId'
                                    placeholder='Selecione o Cartão'
                                    style={{ width: '100%' }}
                                    value={watchCartao}
                                    onChange={(e) => setValue('CartoesId', e.target.value)}
                                />
                            </div>

                            <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                            </div>
                        </div>
                    </form>

                    <Divider />
                    {loading ? <Loading full={false} /> : (
                        <DataTable
                            size='small'
                            stripedRows
                            ref={dt}
                            value={cartoes}
                            onSelectionChange={(e) => setSelectedCartao(e.value)}
                            dataKey="Id"
                            paginator
                            rows={20}
                            rowsPerPageOptions={[10, 20, 40]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            globalFilter={filter}
                            emptyMessage="Nenhum registro encontrado."
                            header={null}
                            responsiveLayout="scroll">

                            <Column field="NomeCartao" header="Nome" headerStyle={{ width: 150 }} />
                            <Column field="Codigo" header="Cartão" headerStyle={{ width: 100 }} body={(rowData: any) => {
                                const bandeira = CARTAO_BANDEIRA.find(el => el.value === rowData.Codigo);
                                return bandeira ? bandeira.descricao : 'Desconhecido';
                            }} />
                            <Column hidden={!util.isDesktop()} field="Tipo" header="Tipo" align="center"
                                body={(rowData: ICartao) => (
                                    rowData.Tipo == "C" ? 'Crédito' : rowData.Tipo == "D" ? 'Débito' : "Parcelado"
                                )} headerStyle={{ width: 140 }}
                            />
                            <Column field="NumeroCartao" header="Número do Documento" />
                            <Column field="CodigoFonteSugerida" header="Fonte Sugerida" bodyStyle={{ fontSize: 11 }} />
                            <Column field="DiaPadraoVencimento" header="Dia Padrão de Vencimento" bodyStyle={{ fontSize: 11 }} />
                            <Column body={(rowData: any) => {
                                return (
                                    <div className="actions flex align-items-center justify-content-between">
                                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                                    </div>
                                );
                            }} style={{ width: 80 }}
                            />
                        </DataTable>
                    )}

                    <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
                        header={`${cartao.CartoesId === undefined ? 'Cadastrando ' : (showDelete ? 'Excluíndo' : 'Alterando')} Cartão Institucional`}
                        modal className="p-fluid" onHide={hideDialog}>
                        <CartaoInstitucionalForm
                            cartao={cartao}
                            setShowDialog={setShowDialog}
                            deletar={deleteCartaoInstitucional}
                            showDelete={showDelete}
                            setShowDelete={setShowDelete}
                            reload={load}
                        />
                    </SCDialog>
                </div>
            </div>
        </div>
    );
};

export default CartaoInstitucional;
