import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCDropdown, SCFieldset, SCInputText } from '../components';


const EncerramentoCaixa = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const [usuarios, setUsuarios] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, watch, clearErrors, setError, setValue, getValues } = methods;

  const isMaster = usuario?.Master;
  const usuarioIdLogado = usuario?.UsuarioId;

  const load = async () => {
    let resp;

    try {
      resp = await api.get('/usuario/listaUsuarioEmpresa', {
        params: { EmpresaId: periodoSelecionado?.EmpresaId },
      });

      if (resp.data) {
        if (!isMaster) {
          let usuarioMaster = resp.data.find((user: any) => user.UsuarioId == usuarioIdLogado)
          setUsuarios(usuarioMaster ? [usuarioMaster] : []);

        } else {
          setUsuarios(resp.data);
        }

      } else {
        setUsuarios([]);
      }

    } catch (error) {
      console.error('Erro ao carregar usuários:', error);
    }
  };

  const watchUsuario = watch('UsuarioId')

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setValue('DataInicial', new Date())
    setValue('DataFinal', new Date())
  }, []);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Encerramento de Caixa</h5>
          </div>

          <div className="col-12">
            <div className='p-4' style={{ backgroundColor: '#de7676', color: 'white', textAlign: 'center' }}>
              <b>Atenção!</b><br /><br />
              Após encerrar o caixa, NENHUMA movimentação financeira poderá ser registrada nesta data.
            </div>
          </div>

          <SCFieldset legend='Movimentação de Caixa' className='col-12 pt-0'>
            <div className='grid align-items-center'>
              <div className="col-12 md:col-2">
                <SCCalendar
                  control={control}
                  label='Data Inicial'
                  name='DataInicial'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Inicial'
                  required
                />
              </div>

              <div className="col-12 md:col-2">
                <SCCalendar
                  control={control}
                  label='Data Final'
                  name='DataFinal'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Final'
                  required
                />
              </div>

              <div className="col-12 md:col-3">
                <SCDropdown
                  name="UsuarioId"
                  options={usuarios}
                  optionLabel='Apelido'
                  optionValue='UsuarioId'
                  placeholder='Selecione o Usuário'
                  label='Usuário'
                  value={watchUsuario}
                  style={{ width: '100%' }}
                  onChange={(e) => setValue('UsuarioId', e.target.value)}
                />
              </div>

              <div className="col-12 md:col-2 pt-5">
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info" type='button' />
              </div>
            </div>
          </SCFieldset>


          <div className='flex col-12 justify-content-between'>
            <SCFieldset legend='Entradas' className='col-12 mt-2 p-0 md:col-4' style={{ color: 'green' }}>
              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}></div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Meu Caixa</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Outras Fontes</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Abastece MeuCaixa:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Sangria:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Dinheiro:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Cartão:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Outros:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Total:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>
            </SCFieldset>

            <SCFieldset legend='Saída' className='col-12 mt-2 p-0 md:col-4' style={{ color: 'red' }}>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}></div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Meu Caixa</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Outras Fontes</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Abastece MeuCaixa:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Sangria:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Dinheiro:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Cartão:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Outros:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Total:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>
            </SCFieldset>

            <SCFieldset legend='Resumo' className='col-12 mt-2 p-0 md:col-4' style={{ color: 'blue' }}>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}></div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Meu Caixa</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Outras Fontes</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Abastece MeuCaixa:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Sangria:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Dinheiro:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Cartão:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Outros:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>

              <div className="grid">
                <div className="col-5 text-sm" style={{ textAlign: "right" }}>Total:</div>
                <div className="col-3" style={{ textAlign: "left" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>

                <div className="col-3" style={{ textAlign: "right" }}>
                  <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ 1.80</span>
                </div>
              </div>
            </SCFieldset>
          </div>

          <SCFieldset legend='Saldo' className='col-12 pt-0'>
            <div className='grid flex justify-content-around align-items-center'>
              <div className="col-12 md:col-2">
                <SCInputText
                  control={control}
                  label='Meu Caixa'
                  name='DataInicial'
                  labelClassName='font-bold'
                  disabled
                />
              </div>

              <div className="col-12 md:col-2">
                <SCInputText
                  control={control}
                  label='Outras Fontes'
                  name='DataInicial'
                  labelClassName='font-bold'
                  disabled
                />
              </div>

              <div className="col-12 md:col-2">
                <SCInputText
                  control={control}
                  label='Total'
                  name='DataInicial'
                  labelClassName='font-bold'
                  disabled
                />
              </div>
            </div>
          </SCFieldset>


          <div className='grid flex justify-content-center'>
            <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
            <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
              <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EncerramentoCaixa;