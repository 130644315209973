import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, AVALIACAO, FEZ_AVALIACAO } from '../utilities/constantes';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDropdown, SCInputText, SCRadioButton } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { InputText } from 'primereact/inputtext';


const NotasTurma = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'NotasTurma';

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [turmas, setTurmas] = useState<any>(null);
  const [disciplinas, setDisciplinas] = useState<any>(null);
  const [disciplina, setDisciplina] = useState<any>(null);
  const [materias, setMaterias] = useState<any>([]);
  const [materia, setMateria] = useState<any>(null);
  const [mes, setMes] = useState<any>();
  const [boletim, setBoletim] = useState<any[]>([]);
  const [saveTime, setSaveTime] = useState<string | null>(null);
  const [avaliacao, setAvaliacao] = useState<any>();
  const [e, setListaBoletim] = useState<any[]>([]);
  const [allMaterias, setAllMaterias] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  const watchCodigoTurma = watch('Codigo');

  const load = async () => {
    const turma = await api.get('/turma/listaVagasOcupadas', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Situacao: 'M'
      }
    });

    if (turma.data) {
      const turmasComCodigoDescricao = turma.data.map((turma: any) => ({
        ...turma,
        CodigoDescricao: `${turma.Codigo} - ${turma.Descricao}`
      }));
      setTurmas(turmasComCodigoDescricao);

    } else {
      setTurmas([]);
    }
  };

  const loadDisciplina = async () => {
    if (watchCodigoTurma) {
      const resultDisciplina = await api.get('/ofertaDisciplina/', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: watchCodigoTurma
        }
      });

      if (resultDisciplina.data) {
        console.log(resultDisciplina.data)
        const disciplinaDescricao = resultDisciplina.data.map((item: any) => ({
          ...item,
          DisciplinaCodDesc: `${item.DisciplinaCodigo} - ${item.DisciplinaDescricao}`
        }));
        setDisciplinas(disciplinaDescricao);
      } else {
        setDisciplinas([]);
      }
    }
  };

  const watchDisciplinaCodigo = watch('DisciplinaCodigo')
  const watchMateriaCodigo = watch('MateriaCodigo')
  const watchFezAvaliacao = watch('')

  const todasMaterias = async () => {
    let result = await api.get('/materia/todasMaterias')

    console.log(result.data)

    if (result.data) {
      setAllMaterias(result.data)
    } else {
      setAllMaterias([])
    }
  }

  const loadMateria = async () => {
    if (watchDisciplinaCodigo && allMaterias.length > 0) {
      const resultMateria = await api.get('/ofertaDisciplina/materiasDaOferta', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: watchCodigoTurma,
          DisciplinaCodigo: watchDisciplinaCodigo
        }
      });

      const materiasResponse = resultMateria.data;
      let listandoMaterias: { Codigo: string, Descricao: string }[] = [];

      materiasResponse.forEach((item: any) => {
        // Verifica e busca cada MateriaCodigo de 1 a 6
        for (let i = 1; i <= 6; i++) {
          const materiaCodigo = item[`MateriaCodigo${i}`];
          if (materiaCodigo) {
            const materia = allMaterias.find((materia: any) => materia.Codigo === materiaCodigo);
            if (materia) {
              listandoMaterias.push({ Codigo: materia.Codigo, Descricao: materia.Codigo + ' - ' + materia.Descricao });
            }
          }
        }
      });

      setMaterias(listandoMaterias);
    }
  };

  const buscaAlunos = async () => {
    let continuar = false
    if (materias.length === 0) {
      continuar = true
    } else {

      if (watchMateriaCodigo) {
        continuar = true
      }
    }

    if (continuar) {
      // console.log('AVALIACAO', avaliacao)
      if (avaliacao) {
        // console.log(periodoSelecionado?.EmpresaId)
        // console.log(periodoSelecionado?.Ano)
        // console.log(periodoSelecionado?.Sequencial)
        // console.log(watchCodigoTurma)
        // console.log(watchDisciplinaCodigo)
        // console.log(watchMateriaCodigo)
        const resultAlunos = await api.get('/boletim/listNotasTurma', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            TurmaCodigo: watchCodigoTurma,
            DisciplinaCodigo: watchDisciplinaCodigo,
            MateriaCodigo: watchMateriaCodigo,
            Avaliacao: avaliacao,
            FezProva: avaliacao.replace("Nota", ""),
            SituacaoAcademica: 'M',
          }
        });

        setBoletim(resultAlunos.data);

        // console.log(resultAlunos.data)
      }
    } else {
      toast.showToast({ severity: 'info', summary: 'Atenção', detail: 'Informe a Matéria' });
      setAvaliacao('')
      return false
    }

  };

  useEffect(() => {
    load();
    todasMaterias()
  }, []);

  useEffect(() => {
    if (watchCodigoTurma) {
      loadDisciplina();
      setAvaliacao('')
      setBoletim([])
      setValue('MateriaCodigo', null)
    }

  }, [watchCodigoTurma]);

  useEffect(() => {
    loadMateria();
    if (watchDisciplinaCodigo) {
      setAvaliacao('')
      setBoletim([])
      setValue('MateriaCodigo', null)
    }
  }, [watchDisciplinaCodigo]);

  useEffect(() => {
    if (avaliacao) {
      buscaAlunos();
    }
  }, [avaliacao]);

  useEffect(() => {
    if (!watchMateriaCodigo) {
      setAvaliacao('')
      setBoletim([])
    }
  }, [watchMateriaCodigo]);

  // const searchBoletins = async () => {
  //   setSaveTime('');

  //   const boletimFaltas = await api.get('/boletim/listBoletim', {
  //     params: {
  //       EmpresaId: periodoSelecionado?.EmpresaId,
  //       Sequencial: periodoSelecionado?.Sequencial,
  //       Ano: periodoSelecionado?.Ano,
  //       Mes: mes,
  //       DisciplinaCodigo: disciplina,
  //       MateriaCodigo: materia,
  //     }
  //   })

  //   setBoletim(boletimFaltas.data)
  // }

  const onEditorValueChange = (props: any, value: any) => {
    let updatedBoletim = [...boletim];
    updatedBoletim[props.rowIndex][props.field] = value;
    updatedBoletim[props.rowIndex]['FezProva' + avaliacao.slice(-2)] = 'S';
    setBoletim(updatedBoletim);
  };

  const inputTextEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: any) => {
      const value = e.target.value;
      onEditorValueChange({ field, rowIndex }, value);
    };

    const handleControl = (e: any) => {
      const charCode = e.charCode;
      const newValue = e.target.value + String.fromCharCode(charCode);

      if (!/^\d{0,2}$/.test(newValue)) {
        e.preventDefault();
      }
    };

    return (
      <InputText
        type="number"
        maxLength={2}
        defaultValue={props.rowData[props.field]}
        onBlur={handleBlur}
        onKeyPress={handleControl}
        style={{ maxWidth: '100%' }}
      />
    );
  };

  const inputNumberEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = parseFloat(e.target.value);
      if (!isNaN(value)) {
        // Limitar o valor máximo para 10.00
        value = Math.min(value, 10.00);

        if (value !== parseFloat(e.target.value)) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Nota máxima permitida é 10.00' });
          return false
        }

        value = parseFloat(value.toFixed(2)); // Formatar para até 2 casas decimais
        onEditorValueChange({ field: field, rowIndex: rowIndex }, value); // Atualizar o estado
      } else {
        let value = e.target.value;
        if (value === '') {
          onEditorValueChange({ field: field, rowIndex: rowIndex }, null);
        } else {
          let numberValue = parseFloat(value);
          if (!isNaN(numberValue)) {
            numberValue = parseFloat(numberValue.toFixed(2)); // Formatar para até 2 casas decimais
            onEditorValueChange({ field: field, rowIndex: rowIndex }, numberValue); // Atualizar o estado
          }
        }
      }
    };

    return (
      <SCInputText
        type="number"
        step="0.01"
        defaultValue={props.rowData[props.field]}
        onBlur={handleBlur}
        disabled={props.rowData.desabilitaNota}
        style={{ borderWidth: 1, borderColor: "#0772b3" }}
      />
    );
  };

  const notaBodyTemplate = (rowData: any) => {
    const value = rowData[avaliacao];
    // return value !== undefined && value !== null && !isNaN(value) ? value.toFixed(2) : '';

    return (<SCInputText
      type="number"
      step="0.01"
      value={value !== undefined && value !== null && !isNaN(value) ? value.toFixed(2) : ''}
      disabled={true}
      style={{ borderWidth: 1, borderColor: "#0772b3" }}
    />)
  };

  const getFezProvaField = (avaliacao: string, rowData: any) => {
    switch (avaliacao) {
      case 'NotaB1':
        return !rowData.FezProvaB1 ? 'L' : rowData.FezProvaB1;
      case 'NotaB2':
        return !rowData.FezProvaB2 ? 'L' : rowData.FezProvaB2;
      case 'NotaRI':
        return !rowData.FezProvaRI ? 'L' : rowData.FezProvaRI;
      case 'NotaB3':
        return !rowData.FezProvaB3 ? 'L' : rowData.FezProvaB3;
      case 'NotaB4':
        return !rowData.FezProvaB4 ? 'L' : rowData.FezProvaB4;
      case 'NotaB5':
        return !rowData.FezProvaB5 ? 'L' : rowData.FezProvaB5;
      case 'NotaB6':
        return !rowData.FezProvaB6 ? 'L' : rowData.FezProvaB6;
      default:
        return null;
    }
  };

  const handleDropdownChange = (selectedValue: any, id: any) => {
    setBoletim((prevBoletim: any) => {
      return prevBoletim.map((boletim: any) => {
        if (boletim.BoletimId !== id) {
          return boletim;
        }

        const updatedBoletim = { ...boletim };

        const updateFezProva = (field: string, notaField: string) => {
          updatedBoletim[field] = selectedValue;
        };

        switch (avaliacao) {
          case 'NotaB1':
            updateFezProva('FezProvaB1', 'NotaB1');
            break;
          case 'NotaB2':
            updateFezProva('FezProvaB2', 'NotaB2');
            break;
          case 'NotaRI':
            updateFezProva('FezProvaRI', 'NotaRI');
            break;
          case 'NotaB3':
            updateFezProva('FezProvaB3', 'NotaB3');
            break;
          case 'NotaB4':
            updateFezProva('FezProvaB4', 'NotaB4');
            break;
          case 'NotaB5':
            updateFezProva('FezProvaB5', 'NotaB5');
            break;
          case 'NotaB6':
            updateFezProva('FezProvaB6', 'NotaB6');
            break;
          default:
            break;
        }
        return updatedBoletim;
      });
    });
  };

  const onSubmit = async () => {

    let resp: any = undefined;

    const updatedListaBoletim = boletim.map(item => {
      const { Nome, NumeroOrdem, ...rest } = item;
      return rest;
    });

    const boletimAluno = updatedListaBoletim.map((item: any) => {

      if (avaliacao === 'NotaB1') {
        if (item.NotaB1 < 0) {
          item.FezProvaB1 = 'L'
          item.NotaB1 = null
        } else {
          if (!item.NotaB1 && item.NotaB1 !== 0) {
            item.FezProvaB1 = 'L'
          } else {
            if (item.NotaB1 >= 0 && item.FezProvaB1 !== 'I') {
              item.FezProvaB1 = 'S'
            } else {
              if (item.FezProvaB1 !== 'S' && item.FezProvaB1 !== 'I') {
                item.NotaB1 = 0
              }
            }
          }
        }
      }

      if (avaliacao === 'NotaB2') {
        if (item.NotaB2 < 0) {
          item.FezProvaB2 = 'L'
          item.NotaB2 = null
        } else {
          if (!item.NotaB2 && item.NotaB2 !== 0) {
            item.FezProvaB2 = 'L'
          } else {
            if (item.NotaB2 >= 0 && item.FezProvaB2 !== 'I') {
              item.FezProvaB2 = 'S'
            } else {
              if (item.FezProvaB2 !== 'S' && item.FezProvaB2 !== 'I') {
                item.NotaB2 = 0
              }
            }
          }
        }
      }

      if (avaliacao === 'NotaRI') {
        if (item.NotaRI < 0) {
          item.FezProvaRI = 'L'
          item.NotaRI = null
        } else {
          if (!item.NotaRI && item.NotaRI !== 0) {
            item.FezProvaRI = 'L'
          } else {
            if (item.NotaRI >= 0 && item.FezProvaRI !== 'I') {
              item.FezProvaRI = 'S'
            } else {
              if (item.FezProvaRI !== 'S' && item.FezProvaRI !== 'I') {
                item.NotaRI = 0
              }
            }
          }
        }
      }

      if (avaliacao === 'NotaB3') {
        if (item.NotaB3 < 0) {
          item.FezProvaB3 = 'L'
          item.NotaB3 = null
        } else {
          if (!item.NotaB3 && item.NotaB3 !== 0) {
            item.FezProvaB3 = 'L'
          } else {
            if (item.NotaB3 >= 0 && item.FezProvaB3 !== 'I') {
              item.FezProvaB3 = 'S'
            } else {
              if (item.FezProvaB3 !== 'S' && item.FezProvaB3 !== 'I') {
                item.NotaB3 = 0
              }
            }
          }
        }
      }

      if (avaliacao === 'NotaB4') {
        if (item.NotaB4 < 0) {
          item.FezProvaB4 = 'L'
          item.NotaB4 = null
        } else {
          if (!item.NotaB4 && item.NotaB4 !== 0) {
            item.FezProvaB4 = 'L'
          } else {
            if (item.NotaB4 >= 0 && item.FezProvaB4 !== 'I') {
              item.FezProvaB4 = 'S'
            } else {
              if (item.FezProvaB4 !== 'S' && item.FezProvaB4 !== 'I') {
                item.NotaB4 = 0
              }
            }
          }
        }
      }

      if (avaliacao === 'NotaB5') {
        if (item.NotaB5 < 0) {
          item.FezProvaB5 = 'L'
          item.NotaB5 = null
        } else {
          if (!item.NotaB5 && item.NotaB5 !== 0) {
            item.FezProvaB5 = 'L'
          } else {
            if (item.NotaB5 >= 0 && item.FezProvaB5 !== 'I') {
              item.FezProvaB5 = 'S'
            } else {
              if (item.FezProvaB5 !== 'S' && item.FezProvaB5 !== 'I') {
                item.NotaB5 = 0
              }
            }
          }
        }
      }

      if (avaliacao === 'NotaB6') {
        if (item.NotaB6 < 0) {
          item.FezProvaB6 = 'L'
          item.NotaB6 = null
        } else {
          if (!item.NotaB6 && item.NotaB6 !== 0) {
            item.FezProvaB6 = 'L'
          } else {
            if (item.NotaB6 >= 0 && item.FezProvaB6 !== 'I') {
              item.FezProvaB6 = 'S'
            } else {
              if (item.FezProvaB6 !== 'S' && item.FezProvaB6 !== 'I') {
                item.NotaB6 = 0
              }
            }
          }
        }
      }

      return item;

    })

    try {
      setLoading(true)
      resp = await api.put('/boletim/multiUpdate', boletimAluno)

      setLoading(false)
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      buscaAlunos();

    } catch (e: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Notas por Turma</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid pt-2'>
            <div className="col-12 md:col-6">
              <SCDropdown
                name="Codigo"
                options={turmas}
                optionLabel="CodigoDescricao"
                optionValue='Codigo'
                placeholder='Selecione a Turma'
                label='Turma'
                style={{ width: '100%' }}
                value={watchCodigoTurma}
                onChange={(e) => setValue('Codigo', e.target.value)}
                required
              />
            </div>
            <div className="col-12 md:col-6">
              <SCDropdown
                name="DisciplinaCodigo"
                options={disciplinas}
                optionLabel='DisciplinaCodDesc'
                optionValue='DisciplinaCodigo'
                placeholder='Selecione a Disciplina'
                label='Disciplina'
                onChange={(e) => setValue('DisciplinaCodigo', e.target.value)}
                value={watchDisciplinaCodigo}
                style={{ width: '100%' }}
              />
            </div>
          </div>

          <div className='grid'>
            <div className="col-12 md:col-6">
              <SCDropdown
                name="MateriaCodigo"
                options={materias}
                optionLabel='Descricao'
                optionValue='Codigo'
                placeholder='Selecione a Matéria'
                label='Matéria'
                onChange={(e) => setValue('MateriaCodigo', e.target.value)}
                style={{ width: '100%' }}
                value={watchMateriaCodigo}
              />
            </div>

            <div className="col-12 md:col-6">
              <SCDropdown
                errors={errors}
                name="Avaliacao"
                label='Avaliação'
                options={AVALIACAO}
                optionLabel='descricao'
                value={avaliacao}
                onChange={(e: any) => setAvaliacao(e.target.value)}
                placeholder='Selecione a Avaliação'
                optionValue='value'
                style={{ width: '100%' }}
                required
              />
            </div>
          </div>

          {/* <div className='grid'>
            <div className="flex col-12 md:col-4 align-items-center mt-3">
              <div className='pb-2'>Classificar por</div>
              <div className="flex ml-3">
                <SCRadioButton
                  control={control}
                  name="RetornoLayout"
                  label='Nºde Ordem'
                  value={1}
                />
                <SCRadioButton
                  control={control}
                  name="RetornoLayout"
                  label='Nome do Aluno'
                  value={2}
                  className='ml-3'
                />
              </div>
            </div>
          </div> */}

          <DataTable
            size='small'
            stripedRows
            dataKey="id"
            value={boletim}
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            emptyMessage="Nenhum registro encontrado."
            editMode='cell'
            responsiveLayout="scroll"
            className='mt-5'
          >

            <Column field="NumeroOrdem" header="Nº Ordem" sortable headerStyle={{ width: 100 }} />
            <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
            <Column field="Nome" header="Nome" sortable headerStyle={{ width: '60%' }} />
            <Column field={avaliacao} header="Nota" headerStyle={{ width: 50 }} editor={inputNumberEditor}
              body={notaBodyTemplate}></Column>

            <Column field="FezAvaliacao" header="Fez Avaliação?" body={(rowData: any) => (
              <div className="col-12 flex justify-content-center">
                <SCDropdown
                  value={getFezProvaField(avaliacao, rowData)}
                  onChange={(e) => handleDropdownChange(e.value, rowData.BoletimId)}
                  options={FEZ_AVALIACAO}
                  optionLabel='descricao'
                  style={{ width: 180 }}
                />
              </div>
            )}
              alignHeader='center'
              align='center'></Column>
          </DataTable>
          <div className='grid flex justify-content-center'>
            <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'>
            </div>
            <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
              <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default NotasTurma;
