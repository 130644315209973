import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SCButton, SCDropdown, SCFieldset, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IMatrizCurricular from '../interfaces/IMatrizCurricular';
import ICurso from '../interfaces/ICurso';
import IDisciplina from '../interfaces/IDisciplina';
import { useAuth } from '../providers/auth';
import Disciplina from './Disciplina';

interface Props {
  matriz?: IMatrizCurricular;
  NovoComBase?: any;
  setMatrizDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteMatriz: boolean;
  setDeleteMatriz(param: boolean): void;
}

const MatrizCurricularForm: React.FC<Props> = ({ setMatrizDialog, NovoComBase, matriz, reload, deletar, deleteMatriz, setDeleteMatriz }) => {

  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente

  const tarefa = 'MatrizCurricularForm';
  const toast = useToast();
  const { usuario, empresaSelecionada } = useAuth();

  // const isKeyboardOpen = useDetectKeyboardOpen();
  const [cursos, setCursos] = useState<ICurso[]>([])
  const [disciplinas, setDisciplinas] = useState<IDisciplina[]>([])
  const [dadosMatriz, setDadosMatriz] = useState<any[]>([])
  const [valida, setValida] = useState<any>();
  const [disciplinaSelecionada, setDisciplinaSelecionada] = useState('');
  const [nomeDisciplina, setNomeDisciplina] = useState<any>('')
  const [editando, setEditando] = useState<boolean>(false)
  const [ChrTotal, setChrTotal] = useState<string>('');

  const defaultValues: IMatrizCurricular = matriz || {
    MatrizCurricularItemId: undefined,
    MatrizCurricularId: undefined,
    Sequencia: 0,
    EmpresaId: empresaSelecionada?.EmpresaId || 0,
    CursoId: 0,
    Cursos: '',
    Descricao: '',
    Periodo: 0,
    DisciplinaId: 0,
    Disciplina: '',
    CargaHoraria: 0,
    Credito: 0,
    Ordem: 0,
    Optativa: 0,
    MatrizCurricularItem: []
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, trigger, clearErrors, setError, setValue, getValues, reset } = methods;

  const watchCurso = watch('CursoId');

  useEffect(() => {
    async function load() {
      const resultDisciplina = await api.get('/disciplina', { params: { Descricao: '' } });
      setDisciplinas(resultDisciplina.data);

      const resultCurso = await api.get('/curso', { params: { Descricao: '', ExigeMatrizCurricular: 1 } });
      setCursos(resultCurso.data);
    }

    load()
  }, [])


  const loadDisciplina = async () => {
    const resultDisciplina = await api.get('/disciplina/combo', { params: { Descricao: '' } });
    setDisciplinas(resultDisciplina.data);

    console.log('executou loadDisciplina')
  }


  useEffect(() => {
    const load = async () => {
      let resp;
      if (NovoComBase) {
        resp = await api.get('/matrizCurricular/itens', { params: { MatrizCurricularId: NovoComBase } })
        setDadosMatriz(resp.data);
      } else {
        resp = await api.get('/matrizCurricular/itens', { params: { MatrizCurricularId: matriz?.MatrizCurricularId } })
        setDadosMatriz(resp.data);
      }

      let total = 0;
      resp.data.forEach((item: any) => {
        total += item.CargaHoraria || 0;
      });

      setChrTotal(total.toString());
    }
    if (matriz?.MatrizCurricularId || NovoComBase)
      load()

  }, [matriz])

  // useEffect(() => {
  //   let total = 0;
  //   dadosMatriz.forEach((item) => {
  //     total += item.CargaHoraria || 0;
  //   });
  //   setChrTotal(total.toString());
  // }, [dadosMatriz]);


  const onSubmit = async (data: IMatrizCurricular) => {

    setValida(false)

    try {

      if (NovoComBase) {
        data.MatrizCurricularId = undefined;
      }
      console.log(dadosMatriz)
      let resp: any = undefined;

      if (data.MatrizCurricularId === undefined) {

        let { Cursos, CargaHoraria, Credito, Periodo, Ordem, DisciplinaId, Sequencia, ...cleanData } = data;

        let postData: IMatrizCurricular = {
          EmpresaId: data.EmpresaId,
          CursoId: data.CursoId,
          Sequencia: data.Sequencia,
          Descricao: data.Descricao?.toUpperCase(),
          MatrizCurricularItem: data.MatrizCurricularItem
        };
        if (NovoComBase) {
          console.log('NovoComBase')
          postData.MatrizCurricularItem = dadosMatriz.map((item: any) => {
            return {
              Periodo: item.Periodo || undefined,
              DisciplinaId: item.DisciplinaId || undefined,
              CargaHoraria: item.CargaHoraria || undefined,
              Credito: item.Credito || undefined,
              Ordem: item.Ordem || undefined,
              Optativa: item.Optativa || undefined,
            }
          })
        } else {
          console.log('Criação Normal')
          postData.MatrizCurricularItem = postData?.MatrizCurricularItem?.map((item: any) => {
            return {
              Periodo: item.Periodo || undefined,
              DisciplinaId: item.DisciplinaId || undefined,
              CargaHoraria: item.CargaHoraria || undefined,
              Credito: item.Credito || undefined,
              Ordem: item.Ordem || undefined,
              Optativa: item.Optativa || undefined,
            }
          })
        }

        resp = await api.post('/matrizCurricular', postData);

      } else {

        let { Cursos, CargaHoraria, Credito, Periodo, Ordem, DisciplinaId, Sequencia, ...cleanData } = data;

        let postData: IMatrizCurricular = {
          EmpresaId: data.EmpresaId,
          CursoId: data.CursoId,
          Sequencia: data.Sequencia,
          Descricao: data.Descricao,
          MatrizCurricularId: data.MatrizCurricularId,
          MatrizCurricularItem: data.MatrizCurricularItem
        };
        postData.MatrizCurricularItem = postData?.MatrizCurricularItem?.map((item: any) => {
          return {
            MatrizCurricularItemId: item.MatrizCurricularItemId || undefined,
            MatrizCurricularId: item.MatrizCurricularId || undefined,
            Periodo: item.Periodo || undefined,
            DisciplinaId: item.DisciplinaId || undefined,
            CargaHoraria: item.CargaHoraria || undefined,
            Credito: item.Credito || undefined,
            Ordem: item.Ordem || undefined,
            Optativa: item.Optativa || undefined,
          }
        })
        resp = await api.put('/matrizCurricular', postData);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setMatrizDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelarEdicao = () => {
    setEditando(false);
    setValue('Periodo', 0)
    setValue('DisciplinaId', 0)
    setValue('Ordem', 0)
    setValue('Credito', 0)
    setValue('CargaHoraria', 0)
  }

  const editar = (e: any) => {
    setEditando(true)

    setValue('MatrizCurricularItemId', e.MatrizCurricularItemId)
    setValue('Periodo', e.Periodo)
    setValue('DisciplinaId', e.DisciplinaId)
    setValue('Ordem', e.Ordem)
    setValue('Credito', e.Credito)
    setValue('CargaHoraria', e.CargaHoraria)

  }

  const cancelar = (e: any) => {
    setDeleteMatriz(false);
    setMatrizDialog(false);
    reset();
    if (NovoComBase) {
      reload();
    }
  }

  const adicionarMatrizItem = () => {
    clearErrors();

    const dados = getValues();

    if (!dados.CursoId) {
      setError('CursoId', { message: 'campo obrigatório', type: 'manual' })
      return false;
    }

    if (!dados.Descricao) {
      setError('Descricao', { message: 'campo obrigatório', type: 'manual' })
      return false;
    }

    if (!dados.Periodo) {
      setError('Periodo', { message: 'campo obrigatório', type: 'manual' })
      return false;
    }

    if (dados.Periodo < 0) {
      setError('Periodo', { message: 'Tamanho inválido', type: 'manual' })
      return false;
    }

    if (!dados.DisciplinaId) {
      setError('DisciplinaId', { message: 'campo obrigatório', type: 'manual' })
      return false;
    }

    if (!dados.CargaHoraria) {
      setError('CargaHoraria', { message: 'campo obrigatório', type: 'manual' })
      // toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Preencha o campo DISCIPLINA' });
      return false;
    }

    let dadosGrid = []

    if (editando) {
      // Verifica se o item já existe na matriz
      let verifica = dadosMatriz.some(x => x.MatrizCurricularItemId === dados.MatrizCurricularItemId);

      // Se o item já existe na matriz
      if (verifica) {
        // Verifica se a disciplina já está cadastrada no mesmo período, mas não é a mesma disciplina que está sendo editada
        let verificaDisciplina = dadosMatriz.find(x => x.DisciplinaId === dados.DisciplinaId && x.Periodo === dados.Periodo && x.MatrizCurricularItemId !== dados.MatrizCurricularItemId);

        // Se a disciplina já estiver cadastrada no mesmo período, mas não for a mesma disciplina que está sendo editada
        if (verificaDisciplina) {
          toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Disciplina já informada!' });
          return false;
        }
      }

      // Se o item já existe na matriz, atualize os dados
      const indice = dadosMatriz.findIndex(x => x.MatrizCurricularItemId === dados.MatrizCurricularItemId);
      const dadosAtuais = [...dadosMatriz];
      dadosAtuais[indice] = {
        ...dadosAtuais[indice],
        Periodo: dados.Periodo,
        Disciplina: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Descricao,
        Codigo: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Codigo,
        DisciplinaId: dados.DisciplinaId,
        Ordem: dados.Ordem,
        Credito: dados.Credito,
        CargaHoraria: dados.CargaHoraria,
        Optativa: null,
        Descricao: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Descricao
      };

      reset({
        EmpresaId: empresaSelecionada?.EmpresaId,
        CursoId: dados.CursoId,
        Sequencia: dados.Sequencia,
        Descricao: dados.Descricao,
        MatrizCurricularItem: dadosAtuais,
        Periodo: dados.Periodo,
        Ordem: 0,
        Credito: 0,
        CargaHoraria: 0,
        MatrizCurricularId: matriz?.MatrizCurricularId,
        DisciplinaId: 0,
        Disciplina: ''

      });

      setDadosMatriz(dadosAtuais);
      loadDisciplina()

    } else {
      // Se não estiver editando, adiciona um novo item à matriz
      let verificaDisciplina = dadosMatriz.some(x => x.DisciplinaId === dados.DisciplinaId);

      // Se a disciplina já estiver cadastrada no mesmo período
      if (verificaDisciplina) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Disciplina já cadastrada no Período' });
        return false;
      }

      dadosGrid = [...dadosMatriz, {
        Periodo: dados.Periodo,
        Disciplina: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Descricao,
        Codigo: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Codigo,
        DisciplinaId: dados.DisciplinaId,
        Ordem: dados.Ordem,
        Credito: dados.Credito,
        CargaHoraria: dados.CargaHoraria,
        Optativa: null,
        Descricao: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Descricao,
      }];

      reset({
        EmpresaId: empresaSelecionada?.EmpresaId,
        CursoId: dados.CursoId,
        Sequencia: dados.Sequencia,
        Descricao: dados.Descricao,
        MatrizCurricularItem: dadosGrid,
        Periodo: dados.Periodo,
        Ordem: 0,
        Credito: 0,
        CargaHoraria: 0,
        MatrizCurricularId: matriz?.MatrizCurricularId,
        DisciplinaId: 0,
        Disciplina: ''
      });

      setDadosMatriz(dadosGrid);
      loadDisciplina()
    }
  }

  const excluir = (e: any) => {
    const buscaIdx: any = dadosMatriz?.findIndex(d => d.DisciplinaId === e.DisciplinaId)
    dadosMatriz?.splice(buscaIdx, 1)
    setValue('MatrizCurricularItem', dadosMatriz);
    setDadosMatriz([...dadosMatriz]);
  }

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const update = result.map((item: any, index: any) => { item.Ordem = index + 1; return item; });

    return update;
  };

  const onDragEnd = async (result: any) => {

    if (!result.dropIndex) {
      return;
    }

    const items = reorder(
      dadosMatriz,
      result.dragIndex,
      result.dropIndex
    );
    const update = await api.put('/matrizCurricularItem/reordenar', items);
    setDadosMatriz(items);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 lg:col-3 md:col-3">
          <SCDropdown
            control={control}
            errors={errors}
            name="CursoId"
            label='Curso'
            options={cursos}
            optionLabel='Descricao'
            optionValue='CursoId'
            disabled={deleteMatriz || matriz?.MatrizCurricularId !== undefined}
            tarefa={tarefa}
          />
        </div>

        <div className="col-3 lg:col-1 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            name="Sequencia"
            label='Sequência'
            tarefa={tarefa}
            disabled={deleteMatriz || matriz?.MatrizCurricularId !== undefined}
            maxLength={2}
          />
        </div>

        <div className="col-6 lg:col-3 md:col-3">
          <SCInputText
            control={control}
            errors={errors}
            name="Descricao"
            label='Descrição'
            tarefa={tarefa}
            disabled={deleteMatriz || matriz?.MatrizCurricularId !== undefined}
            maxLength={20}
            tooltip='Máximo de 20 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>

        <div className="col-3 lg:col-2 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            label='Carga Horária Total'
            value={ChrTotal}
            tarefa={tarefa}
            disabled
          />
        </div>
      </div>

      <SCFieldset legend='Nova Disciplina' className='mt-3' >

        <div className='grid'>
          <div className="col-1">
            <SCInputText
              control={control}
              errors={errors}
              name="Periodo"
              label='Periodo'
              //minLength={2}
              tarefa={tarefa}
              disabled={deleteMatriz}
              // tooltip='Máximo de 20 caracteres'
              // tooltipOptions={{ event: 'focus' }}
              required={!deleteMatriz && valida}
              numberOnly
            />

          </div>

          <div className="col-5">
            <SCDropdown
              control={control}
              errors={errors}
              name="DisciplinaId"
              label='Disciplina'
              options={disciplinas}
              optionLabel='CodigoDescricao'
              optionValue='DisciplinaId'
              disabled={deleteMatriz}
              required={!deleteMatriz && valida}
              resetFilterOnHide={true}
            />
          </div>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="CargaHoraria"
              label='Carga Horária'
              tarefa={tarefa}
              disabled={deleteMatriz}
              maxLength={4}
              // tooltip='Máximo de 4 caracteres'
              // tooltipOptions={{ event: 'focus' }}
              required={!deleteMatriz && valida}
            />
          </div>

          <div className="col-1">
            <SCInputText
              control={control}
              errors={errors}
              name="Credito"
              label='Crédito'
              tarefa={tarefa}
              disabled={deleteMatriz}
              maxLength={4}
            // tooltip='Máximo de 4 caracteres'
            // tooltipOptions={{ event: 'focus' }}
            />
          </div>
          <div className="flex col-1 md:col-2 ">
            {editando ? (
              <>
                <SCButton type='button' icon="pi pi-times" className="p-button-danger mt-auto mr-2" onClick={cancelarEdicao} />
                <SCButton type='button' icon="pi pi-check" className="p-button-success mt-auto" onClick={adicionarMatrizItem} />
              </>
            ) : (
              <SCButton type='button' icon="pi pi-plus" disabled={deleteMatriz} className="p-button-success mt-auto" onClick={adicionarMatrizItem} />
            )}
          </div>
        </div>
      </SCFieldset>

      <div className="grid mt-2">
        <div className="col-12">
          <DataTable
            value={dadosMatriz}
            scrollable={true}
            emptyMessage="Nenhum registro adicionado."
            onRowReorder={(e) => onDragEnd(e)}
          >
            <Column rowReorder rowReorderIcon='pi pi-sort-alt' header="" style={{ maxWidth: 50 }}></Column>

            <Column field="Periodo" header="Período" style={{ maxWidth: 100 }}></Column>
            <Column
              style={{ maxWidth: 500 }}
              header="Disciplina"
              body={(rowData: any) => (
                <>
                  <span >{rowData.Codigo} - {rowData.Descricao}</span>
                </>
              )}
            ></Column>
            <Column field="Credito" header="Crédito" style={{ maxWidth: 150 }}></Column>
            <Column field="CargaHoraria" header="Carga Horária" style={{ maxWidth: 170 }} ></Column>

            <Column
              headerStyle={{ maxWidth: 125 }}
              bodyStyle={{ maxWidth: 100, display: 'flex', justifyContent: 'space-evenly' }}
              body={(rowData: any) => (
                <>
                  <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" type='button' onClick={() => editar(rowData)} />
                  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" type='button' onClick={() => excluir(rowData)} />
                </>
              )}
              style={{ width: 90, textAlign: 'center' }}
            />
          </DataTable>
        </div>
      </div>


      {deleteMatriz ? (
        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
          <Button label="Confirmar Exclusão" icon="pi pi-check"
            className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
            onClick={deletar} type='button' />
        </div>
      ) : (
        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>
      )}
    </form>

  );
};

export default MatrizCurricularForm;
